@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: LatoSemibold;
  src: url(assets/fonts/LatoSemibold.ttf);
}

@font-face {
  font-family: Subway Sans Cond Web;
  src: url(assets/fonts/SubwaySansCond-Medium-Web.woff);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: SubwaySansCond-Black-Web;
  src: url(assets/fonts/SubwaySansCond-Black-Web.woff);
  font-weight: 800;
  font-style: normal;
}


.Checkmx-12
{
  margin-left: 8rem;
  margin-right: 8rem;
}

.Checkpx-12
{
  padding-left: 8rem;
  padding-right: 8rem;


}

.Check
{
  margin-top: -4rem;

}

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 1080px;
  height: 1920px;
  overflow: hidden;
}

* {
  box-sizing: border-box !important;
  font-family: "Subway Sans Cond Web";
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  -moz-user-select: none;
}

img {
  pointer-events: none;
}

.hg-row {
  height: 65px;
}

.hg-button-backspace {
  min-width: 100px !important;
}

.hg-button-em {
  min-width: 90px !important;
}

.hg-button-eem {
  min-width: 110px !important;
}

.hg-button-ent {
  min-width: 110px !important;
}

.hg-button-shift {
  min-width: 140px !important;
}

.hg-button-\.com {
  max-width: 140px !important;
}

.hg-button-\@\@ {
  max-width: 140px !important;
}

.keyboard-button {
  color: #ff6b00;
  font-size: 45px;
  height: auto !important;
  width: auto !important;
  flex: 1 1 0px;
  margin: 5px 10px !important;
}

.keyboard-wrapper .simple-keyboard {
  border-radius: 20px !important;
  /* padding: 20px !important; */
  background: transparent;
  /* border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important; */
}

.keyboard-wrapper .hg-button {
  color: black;
  font-size: 25px;
  height: auto !important;
  width: auto !important;
  margin: 2px 4px !important;
  flex: 1 1 0px;
  border-radius: 10px;
}

.nextBTN {
  background-image: url("./assets/imgs/Next.png");
  position: fixed;
  bottom: 450px;
  left: 320px;
  width: 340px;
  height: 100px;
}

.dataPane {
  width: 100%;
  height: 100%;
  background-image: url("./assets/imgs/ThankBG.png");

  background-size: contain;
  background-repeat: no-repeat;
  
 
}

.scorePane {
  width: 100%;
  height: 100%;
  background-image: url("./assets/imgs/Scores.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.bottomlogo {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 1450px;
  padding-bottom: 100px;
}

.InputBody {
  background-image: url("./assets/imgs/InputBG_.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.InputBody:hover {
  background-image: url("./assets/imgs/InputBG.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.InputBodyL {
  background-image: url("./assets/imgs/InputBGL_.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.InputBodyL:hover {
  background-image: url("./assets/imgs/InputBGL.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.chBOX {
  background-image: url("./assets/imgs/chBOX.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-submit {
  background-image: url("./assets/imgs/submit.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 330px;
  height: 100px
}

.btn-term {
  background-image: url("./assets/imgs/term.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 490px;
  height: 110px;
  margin-top: -55px;
}

.btn-term-close {
  background-image: url("./assets/imgs/TERMS_CLOSE.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 87px;
  margin-top: 850px;
  margin-left:50px;
}


.btn-skip {
  background-image: url("./assets/imgs/skip.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 330px;
  height: 100px;
}

.btn-close {
  background-image: url("./assets/imgs/close.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 330px;
  height: 100px;
  position: fixed;
  bottom: 650px;
  left: 375px;
}

.text-order {
  color: #f3b728;
  font-family: "SubwaySansCond-Black-Web";
}

.text-score {
  font-family: "SubwaySansCond-Black-Web";
  color: white;
}

.text-name {
  color: white;
  font-family: "SubwaySansCond-Black-Web";
}

.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: block;
  background: white;
  width: 70%;
  height: 70%;
  padding: 1rem;
  border-radius: 1rem;
}

.ReactModalPortal {
  z-index: 100;
  position: relative
}

.modal-term {
  width: 840px;
  height: 1195px;
  border: solid 5px white;
  border-radius: 18px;
  margin-top: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-image: url("./assets/imgs/TERMS_CONDITIONS_.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.term-content {
  width: 700px;
  height: 700px;
  background-color: #f3b728;
  border: none;
  font-size: 30px;
  overflow-y: scroll;
  color: #1d9142;
}

.term-content:focus {
  width: 700px;
  height: 700px;
  background-color: #f3b728;
  border: none;
  font-size: 30px;
  overflow-y: scroll;
  color: #1d9142;
}

.term-content:hover {
  width: 700px;
  height: 700px;
  background-color: #f3b728;
  border: none;
  font-size: 30px;
  overflow-y: scroll;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
}

.rotate {
  animation: rotation 15s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}